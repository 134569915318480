import React from "react";
import { Text } from "../components";

const InvitationRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          Σ–Network Staking is a way to support the development of Σ–Network by staking cryptocurrency in the early stages of the project, and to obtain long-term, sustainable and considerable benefits from the rapid growth of Σ–Network.
          </li>
          <li>
          Each Σ–Network Staking participant (Σ–Network Staker) needs to pledge a certain amount of cryptocurrency (currently USDT is supported, and more cryptocurrencies will be supported in the future) to Σ–Network to be eligible to receive exclusive benefits and privileges from Σ–Network.
          </li>
          <li>
          Each Σ–Network Staking will bring the following benefits to its Σ–Network Staker:
            <ol>
              <li>
              A certain amount of Σ–Coins every week. The specific Σ–Network amount is directly proportional to the staking amount. You can find the detailed data about the staking amount and the number of Σ–Coins you will receive on the Σ–Network Staking page.
              </li>
              <li>
              Higher amounts of Mining Reward and Σ–Network Holding Dividend, because these two reward amounts are proportional to the number of Σ–Coins you newly mine or hold respectively.
              </li>
              <li>
              Exclusive and considerable Staking Allowance. You can find the detailed description of Staking Allowance in the Σ–Network Staking page.
              </li>
              <li>
              Many other exclusive benefits and privileges. You can find more detailed descriptions in the Σ–Network Staking page.
              </li>
            </ol>
          </li>
          <li>
          Each user can participate in Σ–Network Staking multiple times, and the above benefits for each Σ–Network Staking will be calculated separately based on the respective take-effect time.
          </li>
          <li>
          The staking period of Σ–Network Stake starts when each pledge takes effect and ends when the Σ–Network mainnet is released. When the Σ–Network mainnet is released, Σ–Network Stakers can choose to release all the pledged cryptocurrencies in order, or exchange all pledged cryptocurrencies for equivalent Σ–Coins at 10% of the Σ–Network market price.
          </li>
          <li>
          When each Σ–Network Staking takes effect for 6 months, its staker can choose to release the staking early. If the staker chooses to release the staking early, Σ–Network will deduct 50% of the staking amount as the early release fee. After that, the Σ–Network Staker will no longer receive the Staking Mining Σ–Coins and Staking Allowance corresponding to this Σ–Network Staking.
          </li>
          <li>
          In addition, every core user who recognizes the values and ideals of the Σ–Network project can earn Referral Staking Reward of up to 20% of the total staking amount in batches by promoting Σ–Network Staking.
          </li>
          <li>
          Staking is a common benefit mechanism in blockchain projects. While obtaining staking benefits, all stakers also need to fully understand the detailed rules of staking to be aware of the possible risks. The unique feature of Σ–Network Staking is that during the staking period, Σ–Network Stakers will regularly receive benefits in the form of USDT or others as hedge for any potential risks.
          </li>
          <li>
          Σ–Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>


);
};

export default InvitationRules;





