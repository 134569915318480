import React from "react";
import { Text } from "../../components";

const AGATBenefitRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

For every Σ–Network user, there are many benefits from mining and holding Σ–Coins, including:
        <br/>

        <ol>
          <li>
          When you newly mine enough Σ–Coins, you will be eligible to receive a Mining Reward.
          </li>
          <li>
          When you hold enough Σ–Coins, you will be eligible to receive Σ–Network Holding Dividend.
          </li>
          <li>
          When Σ–Network project opens the public trading of Σ–Coins, you can earn profits by trading the Σ–Coins you hold.
          </li>
          <li>
          When the Σ–Network project decides to repurchase a portion of Σ–Coins to adjust the open market price, you have the opportunity to earn profits from it.
          </li>
          <li>
          The number of Σ–Coins you hold will determine the level of your Σ–Network ID. When it reaches a certain level, you will have the opportunity to join the Management Committee and participate in proposal review and project decision-making.
          </li>
        </ol>

        <br />
        You can learn more details about this topic in the white paper.

      </Text>
    </div>

);
};

export default AGATBenefitRules;
