import React from "react";
import { Text } from "../../components";

const MineAGATRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        There are 2 ways to mine Crediting Σ–Coins:
        <br/>

        <ol>
          <li>
          Sign-up Bonus
          </li>
          <li>
          Referral Bonus
          </li>
        </ol>
        <br />

        There are 2 ways to mine Crediting Σ–Coins:
        <br/>
        <ol>
          <li>
          Daily Mining
          </li>
          <li>
          Purchase Mining
          </li>
          <li>
          Staking Mining
          </li>
          <li>
          Mining Bonus
          </li>
          <li>
          Event Earning
          </li>
          <li>
          Referral Mining Bonus
          </li>
          <li>
          Referral Purchase Mining
          </li>
          <li>
          Referral Staking Mining
          </li>
        </ol>
        <br />
        You can learn more details about this question on the Mining Rules page.
      </Text>
    </div>
);
};

export default MineAGATRules;

