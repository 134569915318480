import React from "react";
import { Text } from "../components";

const SendRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          With the Send function, you can transfer Σ–Network or USDT from your Σ–Network Wallet to an Σ–Network Wallet belonging to another Σ–Network ID.
          </li>
          <li>
          The currencies you can send include Credited Σ–Network, Spendable USDT, and Withdrawable USDT.
          </li>
          <li>
          You need to ensure that the amount of Credited Σ–Network in your Σ–Network Wallet reaches 5,000 before you can send Σ–Network to other Σ–Network Wallet addresses,  or that the total USDT balance reaches 5 USDT before you can send USDT.
          </li>
          <li>
          You can manually enter a target Σ–Network Wallet address or scan the QR code of the target address. Please make sure the destination address is a correct Σ–Network Wallet address to avoid transfer failure.
          </li>
          <li>
          During the transfer, a gas fee of 1%-3% Σ–Network/USDT of your transfer amount will be charged. This amount will be dynamically adjusted based on the actual fees of the Σ–Network.
          </li>
          <li>
          The Σ–Network or USDT you send will normally be deposited into your designated Σ–Network Wallet address within 0.01-0.05 seconds.
          </li>
          <li>
            Σ–Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>


);
};

export default SendRules;



