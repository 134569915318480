import React from "react";
import { Text } from "../../components";

const MingingRewardRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          Mining Reward will be issued in the form of USDT, and the total rewarding amount is exactly 20% of the total profit of the Σ–Network project during the latest reward period (usually 1 week or 2 weeks).
          </li>
          <li>
          Only miners who have newly obtained more than 500 Credited Σ–Network in the reward period (excluding Σ–Network received from other users) are eligible to participate in the Mining Reward event.
          </li>
          <li>
          The USDT rewarding amount received by each miner is strictly proportional to the number of Credited Σ–Coins newly obtained during the reward period (excluding Σ–Network received from other users).
          </li>
          <li>
          The USDT rewards received by each miner will be credited into the Withdrawable USDT balance in the user's Σ–Network Wallet, then it could be withdrawn or sent to other users.
          </li>
          <li>
          This reward does not conflict with other reward events, so each qualified user can participate in each reward event at the same time.
          </li>
          <li>
          This reward will be officially launched when the total number of Σ–Network users reaches 500,000. All rewards since the launch of the project will be distributed in the first reward event.
          </li>
          <li>
          Σ–Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>

    );
};

export default MingingRewardRules;
