import React from "react";
import { Text } from "../../components";

const EcosystemValue = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        The Σ–Network ecosystem provides free services to all Σ–Network users, thus gathering 1 billion global users and huge potential value.
        <br/>
        The value of the Σ–Network Ecosystem lays the foundation for the value of the Σ–Network project, thus avoiding it from falling into the trap of "only technology, no business" like many other projects.
        <br/>
        The Σ–Network project uses mature Internet business models to transform these potential values into actual business benefits, and then share them fairly with all Σ–Network users, thereby attracting more users to join the Σ–Network project.
        <br />
        You can learn more details about this topic in the white paper.
      </Text>
    </div>
);
};

export default EcosystemValue;
