import React from "react";
import { Text } from "../../components";

const ProjectIntroduction = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        Σ–Network is a decentralized ecosystem that is free and open to users around the world and allows all users to benefit from it. 
        <br/>
        It attracts global users into this ecosystem through free and diverse Internet services(including e-commerce, digital contents, social media, entertainment, etc.), digs commercial value through mature Internet business models, and then gives back this commercial value to all users fairly and openly, to achieve its mission of distributing the commercial benefits of Internet technology development more equitably among global users.
        <br/>
        In the Σ–Network project, advanced blockchain technology serves as the core underlying data storage technology. Through its decentralization, non-tampering, openness and transparency, it ensures the transparency, security and efficiency of the commercial benefit distribution mechanism of the whole ecosystem.
      </Text>
    </div>

);
};

export default ProjectIntroduction;




