import React from "react";
import { Text } from "../components";

const WithdrawRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          With the Withdraw function, you can withdraw the Withdrawable USDT balance from your Σ–Network wallet to your personal wallet.
          </li>
          <li>
          Please make sure that your personal wallet supports the ERC-20 network to avoid withdrawal failures.
          </li>
          <li>
          When the Withdrawable USDT balance in your Σ–Network Wallet reaches 10 USDT, you will be able to submit a withdrawal application. For the Σ–Network Staking participants, this requirement will be reduced to 7.5 USDT.
          </li>
          <li>
          When you withdraw, a 1-4 USDT gas fee will be charged. This amount will be dynamically adjusted with the actual fees of the ERC network. For the Σ–Network Staking participants, the fee will be reduced to 75% of the standard gas fee.
          </li>
          <li>
          Your withdrawal (after deducting the gas fee) will be transferred to your designated wallet address after approval by both the Executive Committee and the Financial Team. This process will take approximately 15 working days.
          </li>
          <li>
          You can submit your next withdrawal application only after your previous withdrawal application has been completed.
          </li>
          <li>
            Σ–Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.、
          </li>
        </ol>
      </Text>
    </div>


);
};

export default WithdrawRules;
