import React from "react";
import { Text } from "../components";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

function createData(
  ra: number,
  t: number,
  a: number,
  n: number,
  ad: number
) {
  return { ra, t, a, n, ad };
}

const table1 = [
  createData(0, 120, 5, 5, 60),
  createData(1000, 120, 10, 6, 120),
  createData(2000, 90, 15, 7, 240),
  createData(5000, 90, 25, 8, 400),
  createData(10000, 60, 35, 9, 840),
  createData(20000, 60, 35, 10, 1200),
  createData(50000, 30, 75, 11, 3600),
  createData(100000, 30, 100, 12, 4800)
];


const MineRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[18px] text-gray-900 w-full"
            size="txtInterSemiBold48">The 2 ways to mine Crediting Σ–Coins:</Text>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
            <div className={"font-inter font-semibold inline"}>
              Sign-up Bonus
            </div>

            <br />
            When you successfully sign up as an Σ–Network member, you will receive 5,000 Crediting Σ–Network. When you
            get Credited Σ–Network through Daily Mining, Purchasing or Σ–Network Staking, an equal amount of Crediting Σ–Network will
            automatically be converted to Credited Σ–Network in your Σ–Network Wallet, until all 5,000 Crediting Σ–Network are
            converted.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Referral Bonus
            </div>
              <br />
              When you invite a friend to successfully sign up as an Σ–Network member, you will also receive 5,000
              Crediting Σ–Network. When he/she gets Credited Σ–Network through Daily Mining, Purchasing or Σ–Network Staking, an equal
              amount of your Crediting Σ–Network will be automatically converted into Credited Σ–Network in your Σ–Network Wallet,
              until all of the 5,000 Crediting Σ–Network are converted.
          </li>
        </ol>
      </Text>
      <Text className="text-[18px] text-gray-900 w-full mt-3"
            size="txtInterSemiBold48">The 8 ways to mine Credited Σ–Coins:</Text>
      <Text className="text-[14px] text-gray-900 w-full leading-[170.00%] "
            size="txtInterRegular14">
        <ol>
          <li>
            <div className={"font-inter font-semibold inline"}>
              Daily Mining
            </div>
              <br />
              This is a way to mine Σ–Coins for free.
              <br />
              When the mining machine starts working, an Σ–Network block will be mined every certain minutes (T). When you
              click each block to confirm it, you will receive a certain number (A) of Σ–Coins. When the un-confirmed
              blocks reach a certain number (N), the mining machine will stop working until you confirm them, and then
              the
              mining machine will start working again.
              <br />
              In this rule, each miner's T, A, and N values are different, depending on the total amount of Referral
            Σ–Network
              (RA in short, which is the Credited Σ–Network mined with the help of your referrals, including the following
              Referral Mining Bonus, Referral Purchase Mining, and Referral Staking Mining) you have mined. The more
              Referral Σ–Network you have mined, the more Credited Σ–Network you will mine for free.
              <br />
              <br />
              Please see the table for details:
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align={"center"}>RA<br />
                        (more than...)</StyledTableCell>
                      <StyledTableCell align="center">T<br />
                        (in minutes)</StyledTableCell>
                      <StyledTableCell align="center">A</StyledTableCell>
                      <StyledTableCell align="center">N</StyledTableCell>
                      <StyledTableCell align="center">Max<br />Σ–Network/Day</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table1.map((row) => (
                      <StyledTableRow key={row.ra}>
                        <StyledTableCell align="center" component="th" scope="row">
                          {row.ra}
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.t}</StyledTableCell>
                        <StyledTableCell align="center">{row.a}</StyledTableCell>
                        <StyledTableCell align="center">{row.n}</StyledTableCell>
                        <StyledTableCell align="center">{row.ad}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Purchase Mining
            </div>
              <br />
              This is a way for you to get additional Σ–Coins while shopping in Σ–Network Store.
              <br />
              There are many products you can buy in Σ–Network Store, which come from Σ–Network suppliers or
              third-party
              platforms that cooperate with Σ–Network.
              <br />
              When you shop in Σ–Network Store, you can get a certain amount of Credited Σ–Coins as a reward for free
              while getting the product itself. You can see the amount of Σ–Network rewarded for each product in Σ–Network Store
              page.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Staking Mining
            </div>
              <br />
              This is a way to regularly (by week) receive large amounts of Σ–Coins in return by participating in
            Σ–Network
              staking.
              <br />
              You can view the instructions, privileges and specific rules for participating in Σ–Network staking on the Σ–Network
              Staking page.
              <br />
              Participating in Σ–Network staking is a way to help Σ–Network grow and gain long-term benefits from the
              growth of it.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Mining Bonus
            </div>
              <br />
              This is a mining method that automatically converts Crediting Σ–Network in your wallet to Credited Σ–Network for
              free.
              In this way, you can mine up to 5,000 Σ–Coins.
              <br />
              Please see Sign-up Bonus for specific rules.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Event Earning
            </div>
              <br />
              When you participate in various reward events of Σ–Network (including Mining Reward, Σ–Network Holding
              Dividend, Promoting Bonus, Staking Allowance), but do not receive USDT rewards, you will receive a random
              amount of Σ–Coins as compensation.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Referral Mining Bonus
            </div>
              <br />
              This is a mining method that automatically converts Crediting Σ–Network in your wallet to Credited Σ–Network for
              free
              through all your referrals’ mining. In this way, you can mine up to 5,000 Σ–Coins from each referral.
              <br />
              Please see Referral Bonus for specific rules.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Referral Purchase Mining
            </div>
              <br />
              When your referrals get Σ–Coins through Purchasing Mining, you, as the inviter, will also receive a
              certain proportion of Σ–Coin rewards.
              <br />
              Please note: The “referrals” mentioned here include not only new users you directly invite to sign up for
            Σ–Network, but also new users they continue to invite, as well as new users invited by new users, and
              so on. The closer you are to the initial purchasing user, the higher proportions of Σ–Coin rewards you
              will get.
          </li>
          <br />
          <li>
            <div className={"font-inter font-semibold inline"}>
              Referral Staking Mining
            </div>
              <br />
              When your referrals get Σ–Coins through Staking Mining every week, you, as the inviter, will also
              receive
              a certain proportion of Σ–Coin rewards.
              <br />
              Please note: The “referrals” mentioned here include not only new users you directly invite to sign up for
            Σ–Network, but also new users they continue to invite, as well as new users invited by new users, and
              so on. The closer you are to the initial staking user, the higher proportions of Σ–Coin rewards you
              will
              get.
          </li>
        </ol>
      </Text>
    </div>
);
};

export default MineRules;
