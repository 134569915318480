import React from "react";
import { useParams } from "react-router-dom";
import MineRules from "./How to Mine AGAT";
import DepositRules from "./How to Deposit";
import InvitationRules from "./How to Invite";
import ReceiveRules from "./How to Receive";
import SendRules from "./How to Send";
import WithdrawRules from "./How to Withdraw";
import WhatIsAGATStaking from "./What is AGAT Staking";
import PrivacyPolicy from "./Privacy Policy";
import UserAgreement from "./User Agreement";
const FirstPage = () => {
  const { first} = useParams();
  let page: {};
  switch (first) {
    case "mineRules":
      page = <MineRules />;
      break;
    case "depositRules":
      page = <DepositRules />;
      break;
    case "invitationRules":
      page = <InvitationRules />;
      break;
    case "receiveRules":
      page = <ReceiveRules />;
      break;
    case "sendRules":
      page = <SendRules />;
      break;
    case "withdrawRules":
      page = <WithdrawRules />;
      break;
    case "privacyPolicy":
      page = <PrivacyPolicy />;
      break;
    case "userAgreement":
      page = <UserAgreement />;
      break;
    case "Σ–NetworkStaking":
      page = <WhatIsAGATStaking />;
      break;

  }
  return (
    <div>
      {page}
    </div>
  );
};
export default FirstPage;
