import React from "react";
import { Text } from "../../components";

const AGATIntroduction = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        Σ–Network is the native utility token of the Σ–Network ecosystem. It serves as a means of value exchange within the platform, promoting users' transactions, rewards and incentives in the Σ–Network Ecosystem.
        <br/>
        Σ–Network holders are eligible for various benefits, including access to ecosystem services, participation in governance, and receipt of rewards.
        <br/>
        In addition, in the future mature Σ–Network Ecosystem, Σ–Network will also be used to exchange goods or services, and will be recognized by Σ–Network's product suppliers and service providers.
        <br />
        You can learn more details about this topic in the white paper.
      </Text>
    </div>

);
};

export default AGATIntroduction;
